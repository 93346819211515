import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyIcon from "@assets/svg/svgui/copy-icon.svg";
import ThumbsUpIcon from "@assets/svg/svgui/thumbs-up-icon.svg";

class CopyUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCopied: false,
    };
  }

  handleIconClick = () => {
    this.setState({
      isCopied: true,
    });
    setTimeout(() => {
      this.setState({ isCopied: false });
    }, 2000);
  };

  render() {
    const { url, size = "45px" } = this.props;
    const { isCopied } = this.state;
    return (
      <CopyToClipboard text={url} onCopy={this.handleIconClick}>
        {isCopied ? (
          <div rel="noopener noreferrer">
            <div className="SVGInline thumbs-up-icon anim-pop3">
              <ThumbsUpIcon
                className="SVGInline-svg thumbs-up-icon anim-pop3-svg"
                style={{ width: size, height: size }}
              />
            </div>
          </div>
        ) : (
          <div rel="noopener noreferrer">
            <div className="SVGInline copy-icon">
              <CopyIcon
                className="SVGInline-svg copy-icon-svg"
                style={{ width: size, height: size }}
              />
            </div>
          </div>
        )}
      </CopyToClipboard>
    );
  }
}

export default CopyUrl;
