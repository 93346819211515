import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { Helmet } from "react-helmet";
import { configureHeader } from "@actions/ui";
import { GA } from "@constants/config";
import Footer from "@components/global/footer/footer";
import { displayOptions } from "@components/form/editor/draftjs-block-render";
import SocialButtons from "@components/global/social/social-buttons";
import { META } from "../../constants/config";

class EditorialDetail extends Component {
  static propTypes = {
    // STORE PROPS
    auth: PropTypes.object,
    content: PropTypes.array,
    browser: PropTypes.object.isRequired,
    platform: PropTypes.object,
    location: PropTypes.object,
    slug: PropTypes.string,
    users: PropTypes.object,
    // STORE FUNCTIONS
    configureHeader: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    if (window) {
      window.scrollTo(0, 0);
      window.ga &&
        window.ga(
          "send",
          "pageview",
          `/${GA.PAGES.editorial}/${this.props.slug}`
        );
      window.fbq && window.fbq("track", "PageView");
    }
    this.props.configureHeader({
      headerVisibilityLocked: false,
      headerTheme: "black",
    });
  };

  componentDidUpdate = () => { };
  componentWillUnmount = () => { };

  getPublishingDate = (date) => {
    const d = moment(date).startOf("day");
    return d.format("MMMM") + " " + d.format("DD") + ", " + d.format("YYYY");
  };

  getPublishingTimestamp = (date) => {
    return moment(date).format();
  };

  getDetailContent = () => {
    const slug = this.props.slug;
    const e = this.props.content.find(
      (post) => post.id === slug || post.slug === slug
    );

    if (e) return e;
    return [];
  };

  getAuthorData = (detailContent) => {
    const authorId = this.props.users.result.find(
      (user) => detailContent.author === user
    );

    if (authorId) {
      return this.props.users.entities.users[authorId];
    }
    return null;
  };

  getAuthorName = (detailContent, isAuthor = false) => {
    let author = null;
    if (detailContent && isAuthor) {
      author = detailContent;
    } else {
      author = this.getAuthorData(detailContent);
    }
    if (author) {
      return `${author.first_name} ${author.last_name}`;
    }
    return null;
  };

  getAuthor = (detailContent) => {
    const author = this.getAuthorData(detailContent);

    if (author) {
      const authorName = this.getAuthorName(author, true);
      return (
        <div className="editorial-detail__author-content">
          {author.picture && (
            <div className="author-thumbnail">
              <img src={author.picture.thumbUrl} alt="" />
            </div>
          )}
          <div className="author-stats">
            <div className="author-stats__name">{authorName}</div>
            <div className="author-stats__publishing-date">
              {this.getPublishingDate(detailContent.updated)}
            </div>
          </div>
        </div>
      );
    }

    return "";
  };

  render() {
    const content = this.getDetailContent();
    const author = this.getAuthor(content);
    const authorName = this.getAuthorName(content);
    let heroImg = null;
    if (content && content.image) {
      heroImg = content.image.url || null;
    }

    let bodyText = "";
    if (content && content.body) {
      bodyText = stateToHTML(
        convertFromRaw(JSON.parse(content.body)),
        displayOptions
      );
    }

    const pageClassnames = classnames("editorial-detail", {
      "no-header-image": isEmpty(heroImg),
      "has-header-image": !isEmpty(heroImg),
    });

    const heroClassnames = classnames("editorial-detail__hero", {
      placeholder: isEmpty(heroImg),
    });

    return (
      <div className={pageClassnames}>
        <Helmet>
          <title>{`${META.title} | Editorial | ${content.title}`}</title>
          <link
            rel="canonical"
            href={`${process.env.GATSBY_TTF_SITE_URL}/editorial/${content.id}`}
          />
          <meta
            property="og:title"
            content={`${META.title} | Editorial | ${content.title}`}
          />
          <meta property="og:type" content="article" />
          {authorName && (
            <meta property="article:author" content={authorName} />
          )}
          <meta
            property="article:published_time"
            content={this.getPublishingTimestamp(content.created)}
          />
          <meta
            property="article:modified_time"
            content={this.getPublishingTimestamp(content.updated)}
          />
          {heroImg && heroImg !== "" && (
            <meta property="og:image" content={heroImg} />
          )}
        </Helmet>
        <div className={heroClassnames}>
          {heroImg && <img src={heroImg} alt={content.title} />}
        </div>
        <div className="container container__event-detail-content">
          <div className="row">
            <div className="col m2"></div>
            <div className="col s12 m10">
              {author && (
                <div className="row">
                  <div className="col s12 m9 offset-m1 col--author">
                    <div className="editorial-detail__author">
                      {this.getAuthor(content)}
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                {this.props.browser.greaterThan.mobile && (
                  <SocialButtons content={content} />
                )}
                <div className="col s12 m9">
                  <div className="editorial-detail__title">{content.title}</div>
                  <div
                    className="editorial-detail__body"
                    dangerouslySetInnerHTML={{ __html: bodyText }}
                  ></div>
                </div>
                {this.props.browser.lessThan.medium && (
                  <SocialButtons content={content} />
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    users: state.eventUsers.data,
    content: state.editorial.data,
    browser: state.browser,
    slug: window.location.pathname.split("/")[2],
    platform: state.platform,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
  };
};

const EditorialDetailRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorialDetail);

export default EditorialDetailRedux;
