import classnames from 'classnames';
import {
  Entity
} from 'draft-js';


// --- DISPLAY --- //
const customStyleMap = {
  SUBSCRIPT: { fontSize: '0.6em', verticalAlign: 'sub' },
  SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' }
};

const inlineStyles = {
  SUBSCRIPT: {
    element: "span",
    attributes: {class: "subscript"},
    style: customStyleMap["SUBSCRIPT"]
  },
  SUPERSCRIPT: {
    element: "span",
    attributes: {class: "superscript"},
    style: customStyleMap["SUPERSCRIPT"]
  }
}


function renderAtomicBlocks(block) {
  const entityKey = block.getEntityAt(0) || null;
  if(!entityKey) {
    return;
  }
  let entity;
  try {
    entity = Entity.get(entityKey);
  } catch (e) {
    return;
  }
  
  const type = entity.getType();
  const data = entity.getData();
  const {
    src = "",
    html = null,
    alignment = null,
    width = null
  } = data;

  const classes = classnames({
    [`align-${alignment}`]: alignment,
    // "responsive-img": (type === "IMAGE"),
  });

  let styles = "";

  if(width) {
    styles += `width: ${width}%; height: auto;`
  } else if(!width && type.toLowerCase() === "image") {
    styles += `width: 40%; height: auto;`
  }

  switch(type) {
    case "draft-js-iframely-plugin-embed":
      return (html);

    case "image":
    case "IMAGE":
      return (`
        <img src=${encodeURI(src)} class="${classes}" style="${styles}"/>
      `);
    // case "IMAGE":
    //   return (`
    //     <figure class="${classes}">
    //       <img src=${src} style="${styles}"/>
    //       <div class="clear"></div>
    //     </figure>
    //   `);
    //   break;

    case "video":
      return (`
        <div class="page__video-wrapper">
          <iframe width="100%" height="auto" src="${encodeURI(src)}" frameborder="0" allowfullscreen"></iframe>
        </div>
      `);

    case "audio":
      return (`
        <iframe width="100%" height="auto" scrolling="no" frameborder="no" src="${encodeURI(src)}"></iframe>
      `);

    default:
      if(html && html !== ""){
        return (`
          <div class="${classes}" style="${styles}">${html}</div>
        `);
      } else {
        return (`
          <div class="${classes}" style="${styles}"></div>
        `); 
      }
  }
}

// a divider entiry
function renderDividerBlock(block) {
  return(`<hr />`);
}


const displayOptions = {
  blockRenderers: {
    atomic: renderAtomicBlocks,
    divider: renderDividerBlock
  },
  inlineStyles: inlineStyles
};

export { displayOptions, renderAtomicBlocks };


// --- EDITOR --- //
const editorStyles = {
  root: {
    fontFamily: '\'Georgia\', serif',
    padding: 20,
    width: 600,
  },
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: '\'Georgia\', serif',
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: '1px solid #ccc',
    cursor: 'text',
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: 'center',
  },
  link: {
    color: '#3b5998',
    textDecoration: 'underline',
  },
};

function getBlockClassNames(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

function renderEditorBlock(block) {

}

export { editorStyles, customStyleMap, getBlockClassNames, renderEditorBlock };
