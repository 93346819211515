import React from "react";
import CopyUrl from "./copy-url";
import InstaIcon from "@assets/svg/svgui/insta-icon.svg";
import FBIcon from "@assets/svg/svgui/fb-icon.svg";
import TwitterIcon from "@assets/svg/svgui/twitter-icon.svg";

const SocialButtons = ({ content, url = window.location.href }) => {
  if (!content || content === "") return null;
  return (
    <div className="col s12 m1 col-social">
      <div className="editorial-detail__social">
        <a
          href={
            "mailto:?body=" +
            encodeURIComponent(url) +
            "&subject=" +
            (content.title ? content.title : "")
          }
        >
          <div className="SVGInline insta-icon">
            <InstaIcon
              className="SVGInline-svg insta-icon-svg"
              style={{ width: 45, height: 45 }}
            />
          </div>
        </a>
        <a
          href={
            "https://www.facebook.com/sharer/sharer.php?u=" +
            encodeURIComponent(url)
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="SVGInline fb-icon">
            <FBIcon
              className="SVGInline-svg fb-icon-svg"
              style={{ width: 45, height: 45 }}
            />
          </div>
        </a>
        <a
          href={
            "https://twitter.com/intent/tweet?url=" + encodeURIComponent(url)
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="SVGInline twitter-icon">
            <TwitterIcon
              className="SVGInline-svg twitter-icon-svg"
              style={{ width: 45, height: 45 }}
            />
          </div>
        </a>
        <CopyUrl url={url} />
      </div>
    </div>
  );
};

export default SocialButtons;
